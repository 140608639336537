.explore-container {
    display: flex;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    font-family: Arial, sans-serif;
  }
  
  .main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
  }
  
  .post {
    background-color: #ffffff;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .post h1 {
    margin-bottom: 10px;
  }
  
  .post img {
    width: 100%;
    max-width: 200px;
    height: auto;
    margin-bottom: 10px;
  }
  
  .post button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  /* Added styles for the post actions */
  .post-actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  
  .post-actions button {
    background: none;
    border: none;
    cursor: pointer;
    color: #888;
  }
  
  .post-actions button:hover {
    color: #333;
  }
  
  .post-actions .right-button {
    margin-left: auto; /* Push the button to the right */
  }
  