.user-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  
  .profile-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .profile-header img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .profile-header h1 {
    font-size: 24px;
    margin-bottom: 5px;
  }
  
  .profile-header p {
    font-size: 14px;
    color: #777;
    margin-bottom: 10px;
  }
  
  .profile-header button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 5px;
  }
  
  .follower-counts {
    display: flex;
    gap: 20px;
    margin-top: 10px;
  }
  
  .user-posts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .user-posts .post {
    background-color: #ffffff;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
  }
  
  .user-posts .post img {
    width: 100%;
    max-width: 200px;
    height: 200px;
    margin-bottom: 10px;
  }
  
  .post-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  
  .post-actions button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    align-items: center;
    color: #888;
  }
  
  .post-actions button:hover {
    color: #333;
  }
  