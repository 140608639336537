.sorting {
  border: 1px solid #ccc;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
}

.sorting h1 {
  margin-bottom: 20px;
}

.sorting button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  width: 100%; /* Set the width to 100% to ensure both buttons are the same size */
  max-width: 200px;
}

.sorting button:hover {
  background-color: #2980b9;
}

/* Center buttons horizontally */
.sorting button:first-child {
  margin-top: auto;
  margin-bottom: 5px;
}

.sorting button:last-child {
  margin-bottom: 20px;
}
