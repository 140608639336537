/* Modal styles */
.post-modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .post-modal.open {
    display: block;
  }
  
  .modal-content {
    background-color: white;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    position: relative;
  }
  
  .close-modal {
    color: #aaa;
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 24px;
    cursor: pointer;
  }
  
  .close-modal:hover,
  .close-modal:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  /* Form styles */
  .modal-content form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .modal-content form input[type="file"] {
    margin-bottom: 10px;
  }
  
  .modal-content form img {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  
  .modal-content form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    resize: vertical;
  }
  
  .modal-content form input[type="submit"] {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .modal-content form input[type="submit"]:hover {
    background-color: #0056b3;
  }
  
  /* Other styles */
  .posts-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .post {
    width: 100%;
    max-width: 600px;
    border: 1px solid #ddd;
    margin: 10px;
    padding: 10px;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .post-content {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .post-img {
    max-width: 100%;
    height: auto;
  }
  
  .post-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .post-actions button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #888;
  }
  
  .post-actions button:first-child {
    margin-right: auto; 
  }
  
  .post-actions button:last-child {
    margin-left: auto; 
  }
  
  
  .post-actions button:hover {
    color: #333;
  }
  