/* Add your existing Sidebar styles here */

.container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 20px;
    margin-left : 60px;
  }
  
  .profile-content {
    flex: 1;
    max-width: 600px;
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .user-profile {
    text-align: center;
  }
  .user-profile img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
    align-self: center;
  }
  
  .edit-section {
    background-color: #f0f0f0;
    padding: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .edit-section textarea,
  .edit-section input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .edit-section button {
    background-color: #007bff;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .profile-section {
    background-color: #f0f0f0;
    padding: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .profile-section p {
    flex: 1;
    margin-right: 20px;
  }
  
  .profile-section button {
    background-color: #007bff;
    color: #ffffff;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
 
  .avatar-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .avatar-options img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .avatar-options img:hover {
    transform: scale(1.1);
  }
  
  .avatar-options button {
    background-color: #007bff;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  